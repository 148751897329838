import S from "./Box.module.sass";
import {
  T_Bath,
  T_Filter_Bedroom,
  T_Filter_Types,
  T_PlanTitle,
} from "../../../../constants/floorplans";
import classNames from "classnames";

const numeral = require("numeral");

type Props = {
  title: T_PlanTitle;
  filterBedroom: T_Filter_Bedroom;
  filterTypes: T_Filter_Types;
  bath: T_Bath;
  interior: number;
  currentFilter: {
    bedrooms: T_Filter_Bedroom | T_Filter_Types;
    types: T_Filter_Bedroom | T_Filter_Types;
  };
};

const Box = ({
  title,
  filterBedroom,
  filterTypes,
  bath,
  interior,
  currentFilter,
}: Props) => {
  // console.log('currentFilter =', currentFilter);

  const boxclasses = () => {
    const classes = [
      S.Box,
      {
        [`${S.Display}`]:
          (currentFilter.bedrooms === undefined &&
            currentFilter.bedrooms === filterBedroom) ||
          (currentFilter.types === undefined &&
            currentFilter.types === filterTypes),
        [`${S.Fade}`]:
          (currentFilter.bedrooms !== undefined &&
            currentFilter.bedrooms !== filterBedroom) ||
          (currentFilter.types !== undefined &&
            currentFilter.types !== filterTypes),
      },
    ];
    return classNames(classes);
  };

  const click = () => {
    window.open(
      `https://presentation.elwynngreen.com/plan-${title.toLowerCase()}`,
      "_self",
    );
  };

  return (
    <div className={boxclasses()} onClick={click}>
      <div className={S.Left}>
        <div className={S.Plan}>Plan</div>
        <div className={S.Title}>{title}</div>
      </div>
      <div className={S.Right}>
        <div className={S.Bold}>{filterBedroom}</div>
        <div>{`${bath} bath`}</div>
        <div>{`${numeral(interior).format("0,000")} SF`}</div>
      </div>
    </div>
  );
};

export default Box;

import S from "./SVG.module.sass";
import Phase1 from "./Phase1";
import Phase2 from "./Phase2";
import Phase3 from "./Phase3";
import Phase4 from "./Phase4";
import Phase5 from "./Phase5";
import Phase6 from "./Phase6";
import Phase7 from "./Phase7";
import Phase8 from "./Phase8";
import Phase9 from "./Phase9";
import Legend from "./Legend";
import { useSelector } from "react-redux";
import {
  selectFilterBedrooms,
  selectFilterTypes,
  selectUnit,
  T_Phase,
} from "../../../store/availabilitySlice";
import classNames from "classnames";
import PLANS, { I_Plan, T_PlanTitle } from "../../../constants/floorplans";

const _ = require("lodash");

const SVG = () => {
  const allUnits = useSelector(selectUnit);
  const filterBedrooms = useSelector(selectFilterBedrooms);
  const filterTypes = useSelector(selectFilterTypes);

  const unitClasses = (unit: string, phase: T_Phase, plan: T_PlanTitle) => {
    const u = _.find(allUnits, { unit, phase });
    const p: I_Plan = _.find(PLANS, { title: plan });

    const classes = [
      S.Unit,
      {
        [`${S.Hold}`]: u?.status === "hold",

        [`${S.Sold}`]: u?.status === "sold",

        [`${S.Disable}`]: u?.status === "disable",

        [`${S.Highlight}`]:
          u?.status !== "hold" &&
          u?.status !== "sold" &&
          u?.status !== "disable" &&
          ((filterBedrooms !== undefined &&
            filterTypes === undefined &&
            filterBedrooms === p?.filterBedrooms) ||
            (filterBedrooms === undefined &&
              filterTypes !== undefined &&
              filterTypes === p?.filterTypes) ||
            (filterBedrooms !== undefined &&
              filterTypes !== undefined &&
              filterBedrooms === p?.filterBedrooms &&
              filterTypes === p?.filterTypes)),
      },
    ];
    return classNames(classes);
  };

  const click = (plan: T_PlanTitle) => {
    window.open(
      `https://presentation.elwynngreen.com/plan-${plan.toLowerCase()}`,
      "_self",
    );
  };

  return (
    <svg
      className={S.SVG}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 570"
    >
      <Phase1 unitClasses={unitClasses} click={click} />
      <Phase2 unitClasses={unitClasses} click={click} />
      <Phase3 unitClasses={unitClasses} click={click} />
      <Phase4 unitClasses={unitClasses} click={click} />
      <Phase5 unitClasses={unitClasses} click={click} />
      <Phase6 unitClasses={unitClasses} click={click} />
      <Phase7 unitClasses={unitClasses} click={click} />
      <Phase8 unitClasses={unitClasses} click={click} />
      <Phase9 unitClasses={unitClasses} click={click} />
      <Legend />
    </svg>
  );
};

export default SVG;

import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (unit: string, phase: T_Phase, plan: T_PlanTitle) => string;
  click: (plan: T_PlanTitle) => void;
};

const Phase2 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_2">
      <g id="PHASE-2-UNITS" className={S.Units}>
        <g id="UNITS-111-113">
          <g
            onClick={() => click("A1")}
            className={unitClasses("113", "Phase 2", "A1")}
            id="UNIT-113-A1"
          >
            <polygon
              className={S.Hot}
              points="1677.3,244.6 1715.8,244.6 1715.8,278.5 1712.3,278.5 1712.3,286.4 1677.3,286.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1687.4023 263.7594)"
                  className={S.Plan}
                >
                  A1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1688.4678 275.8271)"
                className={S.Number}
              >
                113
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A")}
            className={unitClasses("112", "Phase 2", "A")}
            id="UNIT-112-A"
          >
            <polygon
              className={S.Hot}
              points="1677.3,328.2 1715.8,328.2 1715.8,294.3 1712.3,294.3 1712.3,286.4 1677.3,286.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1689.9766 305.572)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1688.5371 317.6397)"
                className={S.Number}
              >
                112
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A2")}
            className={unitClasses("111", "Phase 2", "A2")}
            id="UNIT-111-A2"
          >
            <polygon
              className={S.Hot}
              points="1677.3,370 1715.8,370 1715.8,336.1 1712.3,336.1 1712.3,328.2 1677.3,328.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1685.9922 347.3836)"
                  className={S.Plan}
                >
                  A2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1689.7422 359.4513)"
                className={S.Number}
              >
                111
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-34-35">
          <g
            onClick={() => click("D1")}
            className={unitClasses("35", "Phase 2", "D1")}
            id="UNIT-35-D1"
          >
            <polygon
              className={S.Hot}
              points="1524.1,437.9 1492.6,437.9 1492.6,440.2 1485.7,440.2 1485.7,481.2 1492.6,481.2 1492.6,483.1
					1524.1,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1497.4803 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1499.0134 472.5742)"
                className={S.Number}
              >
                35
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("34", "Phase 2", "D1")}
            id="UNIT-34-D1"
          >
            <polygon
              className={S.Hot}
              points="1524.1,437.9 1555.7,437.9 1555.7,440.2 1562.6,440.2 1562.6,481.2 1555.7,481.2 1555.7,483.1
					1524.1,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1535.6221 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1536.8602 472.5742)"
                className={S.Number}
              >
                34
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-32-33">
          <g
            onClick={() => click("D1")}
            className={unitClasses("33", "Phase 2", "D1")}
            id="UNIT-33-D1"
          >
            <polygon
              className={S.Hot}
              points="1616,437.9 1584.5,437.9 1584.5,440.2 1577.6,440.2 1577.6,481.2 1584.5,481.2 1584.5,483.1
					1616,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1589.3094 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1590.8376 472.5742)"
                className={S.Number}
              >
                33
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("32", "Phase 2", "D1")}
            id="UNIT-32-D1"
          >
            <polygon
              className={S.Hot}
              points="1616,437.9 1647.6,437.9 1647.6,440.2 1654.5,440.2 1654.5,481.2 1647.6,481.2 1647.6,483.1
					1616,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1627.451 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1629.0496 472.5742)"
                className={S.Number}
              >
                32
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-30-31">
          <g
            onClick={() => click("D1")}
            className={unitClasses("31", "Phase 2", "D1")}
            id="UNIT-31-D1"
          >
            <polygon
              className={S.Hot}
              points="1708.7,437.9 1677.2,437.9 1677.2,440.2 1670.3,440.2 1670.3,481.2 1677.2,481.2 1677.2,483.1
					1708.7,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1681.9675 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1684.7711 472.5742)"
                className={S.Number}
              >
                31
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("30", "Phase 2", "D1")}
            id="UNIT-30-D1"
          >
            <polygon
              className={S.Hot}
              points="1708.7,437.9 1740.2,437.9 1740.2,440.2 1747.1,440.2 1747.1,481.2 1740.2,481.2 1740.2,483.1
					1708.7,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1720.1091 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1721.1276 472.5742)"
                className={S.Number}
              >
                30
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-26-29">
          <g
            onClick={() => click("G")}
            className={unitClasses("29", "Phase 2", "G")}
            id="UNIT-29-G"
          >
            <rect
              x="1762.8"
              y="376.5"
              className={S.Hot}
              width="49.5"
              height="33.5"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1782.4355 391.9368)"
                  className={S.Plan}
                >
                  G
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.3027 404.0045)"
                className={S.Number}
              >
                29
              </text>
            </g>
          </g>
          <g
            onClick={() => click("F")}
            className={unitClasses("28", "Phase 2", "F")}
            id="UNIT-28-F"
          >
            <polygon
              className={S.Hot}
              points="1812.3,339.4 1764.9,339.4 1764.9,345.9 1762.8,345.9 1762.8,376.5 1805.8,376.5 1805.8,359.4
					1812.3,359.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1783.2676 356.7559)"
                  className={S.Plan}
                >
                  F
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.3877 368.8236)"
                className={S.Number}
              >
                28
              </text>
            </g>
          </g>
          <g
            onClick={() => click("F")}
            className={unitClasses("27", "Phase 2", "F")}
            id="UNIT-27-F"
          >
            <polygon
              className={S.Hot}
              points="1812.3,302.3 1764.9,302.3 1764.9,308.8 1762.8,308.8 1762.8,339.4 1805.8,339.4 1805.8,322.3
					1812.3,322.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1783.2676 319.1213)"
                  className={S.Plan}
                >
                  F
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.5576 331.189)"
                className={S.Number}
              >
                27
              </text>
            </g>
          </g>
          <g
            onClick={() => click("G")}
            className={unitClasses("26", "Phase 2", "G")}
            id="UNIT-26-G"
          >
            <rect
              x="1762.8"
              y="270.5"
              className={S.Hot}
              width="49.5"
              height="31.8"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1782.4355 284.6655)"
                  className={S.Plan}
                >
                  G
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.3027 296.7332)"
                className={S.Number}
              >
                26
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-20-25">
          <g
            onClick={() => click("G")}
            className={unitClasses("25", "Phase 2", "G")}
            id="UNIT-25-G"
          >
            <rect
              x="1762.8"
              y="220.2"
              className={S.Hot}
              width="49.5"
              height="33.5"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1782.4355 235.2108)"
                  className={S.Plan}
                >
                  G
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.4775 247.2785)"
                className={S.Number}
              >
                25
              </text>
            </g>
          </g>
          <g
            onClick={() => click("F")}
            className={unitClasses("24", "Phase 2", "F")}
            id="UNIT-24-F"
          >
            <polygon
              className={S.Hot}
              points="1812.3,183.1 1764.9,183.1 1764.9,189.6 1762.8,189.6 1762.8,220.2 1805.8,220.2 1805.8,203.1
					1812.3,203.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1783.2676 200.6008)"
                  className={S.Plan}
                >
                  F
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.1826 212.6685)"
                className={S.Number}
              >
                24
              </text>
            </g>
          </g>
          <g
            onClick={() => click("F")}
            className={unitClasses("23", "Phase 2", "F")}
            id="UNIT-23-F"
          >
            <polygon
              className={S.Hot}
              points="1812.3,146 1764.9,146 1764.9,152.5 1762.8,152.5 1762.8,183.1 1805.8,183.1 1805.8,166
					1812.3,166 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1783.2676 162.8162)"
                  className={S.Plan}
                >
                  F
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.4727 174.8839)"
                className={S.Number}
              >
                23
              </text>
            </g>
          </g>
          <g
            onClick={() => click("F")}
            className={unitClasses("22", "Phase 2", "F")}
            id="UNIT-22-F"
          >
            <polygon
              className={S.Hot}
              points="1812.3,108.9 1764.9,108.9 1764.9,115.4 1762.8,115.4 1762.8,146 1805.8,146 1805.8,128.9
					1812.3,128.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1783.2676 125.71)"
                  className={S.Plan}
                >
                  F
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1781.542 137.7777)"
                className={S.Number}
              >
                22
              </text>
            </g>
          </g>
          <g
            onClick={() => click("F")}
            className={unitClasses("21", "Phase 2", "F")}
            id="UNIT-21-F"
          >
            <polygon
              className={S.Hot}
              points="1812.3,71.8 1764.9,71.8 1764.9,78.3 1762.8,78.3 1762.8,108.9 1805.8,108.9 1805.8,91.8
					1812.3,91.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1783.2676 88.6041)"
                  className={S.Plan}
                >
                  F
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1782.7471 100.6718)"
                className={S.Number}
              >
                21
              </text>
            </g>
          </g>
          <g
            onClick={() => click("G")}
            className={unitClasses("20", "Phase 2", "G")}
            id="UNIT-20-G"
          >
            <rect
              x="1762.8"
              y="40"
              className={S.Hot}
              width="49.5"
              height="31.8"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1782.4355 54.1484)"
                  className={S.Plan}
                >
                  G
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1780.9619 66.2161)"
                className={S.Number}
              >
                20
              </text>
            </g>
          </g>
        </g>
      </g>
      <polygon
        id="PHASE-2-OUTLINE"
        className={S.Outline}
        points="1716.8,0 1920,0 1920,570 1470.2,570 1470.2,420.3 1652.2,420.3 1652.2,122
		1716.8,122 	"
      />
      <g id="PHASE-2-LABEL" className={S.PhaseLabel}>
        <rect x="1470.2" y="549.3" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 1477.6511 564.3414)">PHASE 2</text>
      </g>
    </g>
  );
};

export default Phase2;

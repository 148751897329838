import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (unit: string, phase: T_Phase, plan: T_PlanTitle) => string;
  click: (plan: T_PlanTitle) => void;
};

const Phase6 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_6">
      <g id="PHASE-6-UNITS" className={S.Units}>
        <g id="UNITS-52-55">
          <g
            onClick={() => click("D3")}
            className={unitClasses("55", "Phase 6", "D3")}
            id="UNIT-55-D3"
          >
            <polygon
              className={S.Hot}
              points="967.4,306.8 967.4,262.9 936.8,262.9 936.8,266.5 929.4,266.5 929.4,306.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 939.8748 285.2048)"
                  className={S.Plan}
                >
                  D3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 942.8551 297.2725)"
                className={S.Number}
              >
                55
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D")}
            className={unitClasses("54", "Phase 6", "D")}
            id="UNIT-54-D"
          >
            <polygon
              className={S.Hot}
              points="891.5,306.8 891.5,262.9 922.1,262.9 922.1,266.5 929.4,266.5 929.4,306.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 905.7496 285.2048)"
                  className={S.Plan}
                >
                  D
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 904.4185 297.2725)"
                className={S.Number}
              >
                54
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D")}
            className={unitClasses("53", "Phase 6", "D")}
            id="UNIT-53-D"
          >
            <polygon
              className={S.Hot}
              points="853.5,306.8 853.5,262.9 884.1,262.9 884.1,266.5 891.5,266.5 891.5,306.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 867.6079 285.2048)"
                  className={S.Plan}
                >
                  D
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 866.5668 297.2725)"
                className={S.Number}
              >
                53
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D2")}
            className={unitClasses("52", "Phase 6", "D2")}
            id="UNIT-52-D2"
          >
            <polygon
              className={S.Hot}
              points="815.5,306.8 815.5,262.9 846.1,262.9 846.1,266.5 853.5,266.5 853.5,306.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 825.4819 285.2048)"
                  className={S.Plan}
                >
                  D2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 828.4954 297.2725)"
                className={S.Number}
              >
                52
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-46-47">
          <g
            onClick={() => click("D5")}
            className={unitClasses("47", "Phase 6", "D5")}
            id="UNIT-47-D5"
          >
            <polygon
              className={S.Hot}
              points="852.9,437.9 821.4,437.9 821.4,440.2 814.5,440.2 814.5,481.2 821.4,481.2 821.4,483.1
					852.9,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 824.6946 460.5065)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 827.4933 472.5742)"
                className={S.Number}
              >
                47
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D5")}
            className={unitClasses("46", "Phase 6", "D5")}
            id="UNIT-46-D5"
          >
            <polygon
              className={S.Hot}
              points="852.9,437.9 884.4,437.9 884.4,440.2 891.3,440.2 891.3,481.2 884.4,481.2 884.4,483.1
					852.9,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 862.8362 460.5065)"
                  className={S.Plan}
                >
                  D5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 865.3801 472.5742)"
                className={S.Number}
              >
                46
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-44-45">
          <g
            onClick={() => click("D1")}
            className={unitClasses("45", "Phase 6", "D1")}
            id="UNIT-45-D1"
          >
            <polygon
              className={S.Hot}
              points="945.1,437.9 913.6,437.9 913.6,440.2 906.7,440.2 906.7,481.2 913.6,481.2 913.6,483.1
					945.1,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 918.373 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 919.616 472.5742)"
                className={S.Number}
              >
                45
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("44", "Phase 6", "D1")}
            id="UNIT-44-D1"
          >
            <polygon
              className={S.Hot}
              points="945.1,437.9 976.6,437.9 976.6,440.2 983.5,440.2 983.5,481.2 976.6,481.2 976.6,483.1
					945.1,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 956.5146 460.5065)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 957.4628 472.5742)"
                className={S.Number}
              >
                44
              </text>
            </g>
          </g>
        </g>
        <g
          onClick={() => click("D1")}
          className={unitClasses("43", "Phase 6", "D1")}
          id="UNIT-43-D1"
        >
          <polygon
            className={S.Hot}
            points="998.9,437.9 1030.4,437.9 1030.4,440.2 1037.3,440.2 1037.3,481.2 1030.4,481.2 1030.4,483.1
				998.9,483.1 			"
          />
          <g>
            <g>
              <text
                transform="matrix(1 0 0 1 1010.4309 460.5065)"
                className={S.Plan}
              >
                D1
              </text>
            </g>
            <text
              transform="matrix(1 0 0 1 1011.6691 472.5742)"
              className={S.Number}
            >
              43
            </text>
          </g>
        </g>
        <g id="UNITS-48-51">
          <g
            onClick={() => click("D2")}
            className={unitClasses("51", "Phase 6", "D2")}
            id="UNIT-51-D2"
          >
            <polygon
              className={S.Hot}
              points="967.4,352.9 967.4,396.8 936.8,396.8 936.8,393.1 929.4,393.1 929.4,352.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 939.907 374.0002)"
                  className={S.Plan}
                >
                  D2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 944.1256 386.0679)"
                className={S.Number}
              >
                51
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D")}
            className={unitClasses("50", "Phase 6", "D")}
            id="UNIT-50-D"
          >
            <polygon
              className={S.Hot}
              points="891.5,352.9 891.5,396.8 922.1,396.8 922.1,393.1 929.4,393.1 929.4,352.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 905.7496 374.0002)"
                  className={S.Plan}
                >
                  D
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 904.1987 386.0679)"
                className={S.Number}
              >
                50
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D")}
            className={unitClasses("49", "Phase 6", "D")}
            id="UNIT-49-D"
          >
            <polygon
              className={S.Hot}
              points="853.5,352.9 853.5,396.8 884.1,396.8 884.1,393.1 891.5,393.1 891.5,352.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 867.6079 374.0002)"
                  className={S.Plan}
                >
                  D
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 866.1019 386.0679)"
                className={S.Number}
              >
                49
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D2")}
            className={unitClasses("48", "Phase 6", "D2")}
            id="UNIT-48-D2"
          >
            <polygon
              className={S.Hot}
              points="815.5,352.9 815.5,396.8 846.1,396.8 846.1,393.1 853.5,393.1 853.5,352.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 825.4819 374.0002)"
                  className={S.Plan}
                >
                  D2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 828.0452 386.0679)"
                className={S.Number}
              >
                48
              </text>
            </g>
          </g>
        </g>
      </g>
      <polygon
        id="PHASE-6-OUTLINE"
        className={S.Outline}
        points="795.7,242.7 795.7,570 1109.4,570 1109.4,420.3 989.5,420.3 989.5,242.7 	"
      />
      <g id="PHASE-6-LABEL" className={S.PhaseLabel}>
        <rect x="795.7" y="549.3" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 803.1169 564.3414)">PHASE 6</text>
      </g>
    </g>
  );
};

export default Phase6;

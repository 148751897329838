import { useEffect, useState } from "@wordpress/element";
import { useAppDispatch } from "../store";
import gsap, { Power1 } from "gsap";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import S from "./App.module.sass";
// @ts-ignore
import GothamBook from "../fonts/Gotham-Book.ttf";
// @ts-ignore
import GothamMedium from "../fonts/Gotham-Medium.otf";
// @ts-ignore
import GothamBold from "../fonts/Gotham-Bold.ttf";
import { fetchUnits } from "../store/availabilitySlice";
import Sitemap from "./Sitemap";
import Plans from "./Plans";

const theme = createTheme({
  palette: {
    primary: {
      main: "#baee58",
    },
  },
});

const AppRoot = styled.div`
  @font-face {
    font-family: "Gotham";
    font-weight: 400;
    src: url(${GothamBook}) format("opentype");
  }
  @font-face {
    font-family: "Gotham";
    font-weight: 500;
    src: url(${GothamMedium}) format("opentype");
  }
  @font-face {
    font-family: "Gotham";
    font-weight: bold;
    src: url(${GothamBold}) format("opentype");
  }
`;

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const myTimeout = setTimeout(() => {
    setLoading(false);
  }, 1000);

  useEffect(() => {
    dispatch(fetchUnits());
  }, []);

  useEffect(() => {
    // console.log('loading changed =', loading);
    if (loading) {
      gsap.to("#preloading", {
        autoAlpha: 0,
        duration: 1,
        ease: Power1.easeInOut,
        onComplete: () => {
          gsap.set("#preloading", { y: -9999 });
          clearTimeout(myTimeout);
        },
      });
    }
  }, [loading]);
  return (
    <ThemeProvider theme={theme}>
      <AppRoot>
        <div className={S.App}>
          <div className={S.Container__Sitemap}>
            <div id={`labels`} className={S.Labels}>
              <div className={S.ComingSoon}>Coming Soon</div>
              <div className={S.NowSelling}>Now Selling</div>
            </div>
            <Sitemap />
          </div>
          <div className={S.Container__Plans}>
            <Plans />
          </div>
          <div id={`preloading`} className={S.Preloading}>
            {/*<Box sx={{display: 'flex'}}>*/}
            {/*	<CircularProgress color={`primary`} />*/}
            {/*</Box>*/}
          </div>
        </div>
      </AppRoot>
    </ThemeProvider>
  );
}

export default App;

import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (unit: string, phase: T_Phase, plan: T_PlanTitle) => string;
  click: (plan: T_PlanTitle) => void;
};

const Phase3 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_3">
      <g id="PHASE-3-UNITS" className={S.Units}>
        <g id="UNITS-91-96">
          <g
            onClick={() => click("B3")}
            className={unitClasses("96", "Phase 3", "B3")}
            id="UNIT-96-B3"
          >
            <rect
              x="1363.7"
              y="371.5"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.449 387.3214)"
                className={S.Plan}
              >
                B3
              </text>
              <text
                transform="matrix(1 0 0 1 1404.374 386.6141)"
                className={S.Number}
              >
                96
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B1")}
            className={unitClasses("95", "Phase 3", "B1")}
            id="UNIT-95-B1"
          >
            <rect
              x="1363.7"
              y="347.2"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 363.0651)"
                className={S.Plan}
              >
                B1
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 362.3578)"
                className={S.Number}
              >
                95
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("94", "Phase 3", "B")}
            id="UNIT-94-B"
          >
            <rect
              x="1363.7"
              y="322.9"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.449 338.8086)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1404.374 338.1013)"
                className={S.Number}
              >
                94
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B1")}
            className={unitClasses("93", "Phase 3", "B1")}
            id="UNIT-93-B1"
          >
            <rect
              x="1363.7"
              y="298.7"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.449 314.5524)"
                className={S.Plan}
              >
                B1
              </text>
              <text
                transform="matrix(1 0 0 1 1404.374 313.8451)"
                className={S.Number}
              >
                93
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("92", "Phase 3", "B")}
            id="UNIT-92-B"
          >
            <rect
              x="1363.7"
              y="274.4"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 291.1258)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 290.4185)"
                className={S.Number}
              >
                92
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B4")}
            className={unitClasses("91", "Phase 3", "B4")}
            id="UNIT-91-B4"
          >
            <rect
              x="1363.7"
              y="250.2"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 266.0397)"
                className={S.Plan}
              >
                B4
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 265.3324)"
                className={S.Number}
              >
                91
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-87-90">
          <g
            onClick={() => click("B5")}
            className={unitClasses("90", "Phase 3", "B5")}
            id="UNIT-90-B5"
          >
            <rect
              x="1363.7"
              y="211.4"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 227.2677)"
                className={S.Plan}
              >
                B5
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 226.5604)"
                className={S.Number}
              >
                90
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B1")}
            className={unitClasses("89", "Phase 3", "B1")}
            id="UNIT-89-B1"
          >
            <rect
              x="1363.7"
              y="187.1"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 203.0115)"
                className={S.Plan}
              >
                B1
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 202.3042)"
                className={S.Number}
              >
                89
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("88", "Phase 3", "B")}
            id="UNIT-88-B"
          >
            <rect
              x="1363.7"
              y="162.9"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 178.7548)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 178.0475)"
                className={S.Number}
              >
                88
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("87", "Phase 3", "B")}
            id="UNIT-87-B"
          >
            <rect
              x="1363.7"
              y="138.6"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1372.4495 154.4985)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1404.3745 153.7912)"
                className={S.Number}
              >
                87
              </text>
            </g>
          </g>
        </g>
        <g
          onClick={() => click("D1")}
          className={unitClasses("42", "Phase 3", "D1")}
          id="UNIT-42-D1"
        >
          <polygon
            className={S.Hot}
            points="1151.1,437.9 1182.6,437.9 1182.6,440.2 1189.5,440.2 1189.5,481.2 1182.6,481.2 1182.6,483.1
				1151.1,483.1 			"
          />
          <g>
            <g>
              <text
                transform="matrix(1 0 0 1 1162.6343 458.7775)"
                className={S.Plan}
              >
                D1
              </text>
            </g>
            <text
              transform="matrix(1 0 0 1 1163.9427 470.8452)"
              className={S.Number}
            >
              42
            </text>
          </g>
        </g>
        <g id="UNITS-40-41">
          <g
            onClick={() => click("D1")}
            className={unitClasses("41", "Phase 3", "D1")}
            id="UNIT-41-D1"
          >
            <polygon
              className={S.Hot}
              points="1244.9,437.9 1213.4,437.9 1213.4,440.2 1206.5,440.2 1206.5,481.2 1213.4,481.2 1213.4,483.1
					1244.9,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1218.0775 458.7775)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1220.5911 470.8452)"
                className={S.Number}
              >
                41
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("40", "Phase 3", "D1")}
            id="UNIT-40-D1"
          >
            <polygon
              className={S.Hot}
              points="1244.9,437.9 1276.5,437.9 1276.5,440.2 1283.4,440.2 1283.4,481.2 1276.5,481.2 1276.5,483.1
					1244.9,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1256.5079 458.7775)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1257.2363 470.8452)"
                className={S.Number}
              >
                40
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-38-39">
          <g
            onClick={() => click("D1")}
            className={unitClasses("39", "Phase 3", "D1")}
            id="UNIT-39-D1"
          >
            <polygon
              className={S.Hot}
              points="1337.9,437.9 1306.4,437.9 1306.4,440.2 1299.5,440.2 1299.5,481.2 1306.4,481.2 1306.4,483.1
					1337.9,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1311.0398 458.7775)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1312.3981 470.8452)"
                className={S.Number}
              >
                39
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("38", "Phase 3", "D1")}
            id="UNIT-38-D1"
          >
            <polygon
              className={S.Hot}
              points="1337.9,437.9 1369.4,437.9 1369.4,440.2 1376.3,440.2 1376.3,481.2 1369.4,481.2 1369.4,483.1
					1337.9,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1349.3246 458.7775)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1350.7678 470.8452)"
                className={S.Number}
              >
                38
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-36-37">
          <g
            onClick={() => click("D1")}
            className={unitClasses("37", "Phase 3", "D1")}
            id="UNIT-37-D1"
          >
            <polygon
              className={S.Hot}
              points="1431,437.9 1399.5,437.9 1399.5,440.2 1392.6,440.2 1392.6,481.2 1399.5,481.2 1399.5,483.1
					1431,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1404.1537 458.7775)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1405.7668 470.8452)"
                className={S.Number}
              >
                37
              </text>
            </g>
          </g>
          <g
            onClick={() => click("D1")}
            className={unitClasses("36", "Phase 3", "D1")}
            id="UNIT-36-D1"
          >
            <polygon
              className={S.Hot}
              points="1431,437.9 1462.6,437.9 1462.6,440.2 1469.5,440.2 1469.5,481.2 1462.6,481.2 1462.6,483.1
					1431,483.1 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1442.5841 458.7775)"
                  className={S.Plan}
                >
                  D1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1443.9424 470.8452)"
                className={S.Number}
              >
                36
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-14-19">
          <g
            onClick={() => click("A5")}
            className={unitClasses("19", "Phase 3", "A5")}
            id="UNIT-19-A5"
          >
            <polygon
              className={S.Hot}
              points="1642.6,55.9 1642.6,52.4 1655.8,52.4 1655.8,55.9 1661,55.9 1661,94.2 1628.7,94.2 1628.7,91.2
					1619.7,91.2 1619.7,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1631.1389 73.3165)"
                  className={S.Plan}
                >
                  A5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1635.2999 85.3842)"
                className={S.Number}
              >
                19
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A6")}
            className={unitClasses("18", "Phase 3", "A6")}
            id="UNIT-18-A6"
          >
            <polygon
              className={S.Hot}
              points="1596.7,55.9 1596.7,52.4 1583.5,52.4 1583.5,55.9 1578.3,55.9 1578.3,94.2 1610.7,94.2
					1610.7,91.2 1619.7,91.2 1619.7,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1589.6082 73.3165)"
                  className={S.Plan}
                >
                  A6
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1594.068 85.3842)"
                className={S.Number}
              >
                18
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7")}
            className={unitClasses("17", "Phase 3", "A7")}
            id="UNIT-17-A7"
          >
            <polygon
              className={S.Hot}
              points="1555.4,55.9 1555.4,52.4 1542.2,52.4 1542.2,55.9 1537,55.9 1537,94.2 1569.3,94.2 1569.3,91.2
					1578.3,91.2 1578.3,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1548.5959 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1552.921 85.3842)"
                className={S.Number}
              >
                17
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7")}
            className={unitClasses("16", "Phase 3", "A7")}
            id="UNIT-16-A7"
          >
            <polygon
              className={S.Hot}
              points="1514.1,55.9 1514.1,52.4 1500.9,52.4 1500.9,55.9 1495.7,55.9 1495.7,94.2 1528,94.2 1528,91.2
					1537,91.2 1537,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1507.2791 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1511.3492 85.3842)"
                className={S.Number}
              >
                16
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7")}
            className={unitClasses("15", "Phase 3", "A7")}
            id="UNIT-15-A7"
          >
            <polygon
              className={S.Hot}
              points="1472.8,55.9 1472.8,52.4 1459.6,52.4 1459.6,55.9 1454.4,55.9 1454.4,94.2 1486.7,94.2
					1486.7,91.2 1495.7,91.2 1495.7,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1465.9622 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1470.2072 85.3842)"
                className={S.Number}
              >
                15
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A8")}
            className={unitClasses("14", "Phase 3", "A8")}
            id="UNIT-14-A8"
          >
            <polygon
              className={S.Hot}
              points="1431.5,55.9 1431.5,52.4 1418.2,52.4 1418.2,55.9 1413.1,55.9 1413.1,94.2 1445.4,94.2
					1445.4,91.2 1454.4,91.2 1454.4,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1424.5095 73.3165)"
                  className={S.Plan}
                >
                  A8
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1428.5953 85.3842)"
                className={S.Number}
              >
                14
              </text>
            </g>
          </g>
        </g>
      </g>
      <polygon
        id="PHASE-3-OUTLINE"
        className={S.Outline}
        points="1109.4,570 1109.4,420.3 1342.9,420.3 1342.9,122 1384.5,122 1384.5,0 1716.8,0
		1716.8,122 1453.5,122 1453.5,420.3 1470.2,420.3 1470.2,570 	"
      />
      <g id="PHASE-3-LABEL" className={S.PhaseLabel}>
        <rect x="1109.4" y="549.3" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 1116.7815 564.3414)">PHASE 3</text>
      </g>
    </g>
  );
};

export default Phase3;

import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (unit: string, phase: T_Phase, plan: T_PlanTitle) => string;
  click: (plan: T_PlanTitle) => void;
};

const Phase1 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_1">
      <g id="PHASE-1-UNITS" className={S.Units}>
        <g id="UNITS-105-109">
          <g
            onClick={() => click("A")}
            className={unitClasses("109", "Phase 1", "A")}
            id="UNIT-109-A"
          >
            <polygon
              className={S.Hot}
              points="1626.5,342.4 1587.9,342.4 1587.9,309.9 1591.6,309.9 1591.6,301.4 1626.5,301.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1602.0929 321.1338)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1598.6289 333.2015)"
                className={S.Number}
              >
                109
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A")}
            className={unitClasses("108", "Phase 1", "A")}
            id="UNIT-108-A"
          >
            <polygon
              className={S.Hot}
              points="1626.5,301.4 1587.9,301.4 1587.9,268.9 1591.6,268.9 1591.6,260.4 1626.5,260.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1602.0929 280.171)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1598.7139 292.2386)"
                className={S.Number}
              >
                108
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A")}
            className={unitClasses("107", "Phase 1", "A")}
            id="UNIT-107-A"
          >
            <polygon
              className={S.Hot}
              points="1626.5,260.4 1587.9,260.4 1587.9,227.9 1591.6,227.9 1591.6,219.4 1626.5,219.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1602.0929 239.2073)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1598.8838 251.275)"
                className={S.Number}
              >
                107
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3")}
            className={unitClasses("106", "Phase 1", "A3")}
            id="UNIT-106-A3"
          >
            <polygon
              className={S.Hot}
              points="1626.5,219.4 1587.9,219.4 1587.9,186.9 1591.6,186.9 1591.6,178.4 1626.5,178.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1598.0753 198.244)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1598.6289 210.3117)"
                className={S.Number}
              >
                106
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A2")}
            className={unitClasses("105", "Phase 1", "A2")}
            id="UNIT-105-A2"
          >
            <polygon
              className={S.Hot}
              points="1626.5,137.3 1587.9,137.3 1587.9,169.8 1591.6,169.8 1591.6,178.4 1626.5,178.4 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1598.1085 157.2808)"
                  className={S.Plan}
                >
                  A2
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1598.8037 169.3485)"
                className={S.Number}
              >
                105
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-97-104">
          <g
            onClick={() => click("B3")}
            className={unitClasses("104", "Phase 1", "B3")}
            id="UNIT-104-B3"
          >
            <rect
              x="1477.7"
              y="137.3"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 154.4079)"
                className={S.Plan}
              >
                B3
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 153.7007)"
                className={S.Number}
              >
                104
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B1")}
            className={unitClasses("103", "Phase 1", "B1")}
            id="UNIT-103-B1"
          >
            <rect
              x="1477.7"
              y="161.6"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 178.5348)"
                className={S.Plan}
              >
                B1
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 177.8275)"
                className={S.Number}
              >
                103
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("102", "Phase 1", "B")}
            id="UNIT-102-B"
          >
            <rect
              x="1477.7"
              y="185.9"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 202.6617)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 201.9544)"
                className={S.Number}
              >
                102
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B1")}
            className={unitClasses("101", "Phase 1", "B1")}
            id="UNIT-101-B1"
          >
            <rect
              x="1477.7"
              y="210.1"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 226.7885)"
                className={S.Plan}
              >
                B1
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 226.0812)"
                className={S.Number}
              >
                101
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("100", "Phase 1", "B")}
            id="UNIT-100-B"
          >
            <rect
              x="1477.7"
              y="234.4"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 250.9154)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 250.2081)"
                className={S.Number}
              >
                100
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B1")}
            className={unitClasses("99", "Phase 1", "B1")}
            id="UNIT-99-B1"
          >
            <rect
              x="1477.7"
              y="258.6"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 275.0422)"
                className={S.Plan}
              >
                B1
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 274.335)"
                className={S.Number}
              >
                99
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B")}
            className={unitClasses("98", "Phase 1", "B")}
            id="UNIT-98-B"
          >
            <rect
              x="1477.7"
              y="282.9"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 299.1693)"
                className={S.Plan}
              >
                B
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 298.462)"
                className={S.Number}
              >
                98
              </text>
            </g>
          </g>
          <g
            onClick={() => click("B3")}
            className={unitClasses("97", "Phase 1", "B3")}
            id="UNIT-97-B3"
          >
            <rect
              x="1477.7"
              y="307.1"
              className={S.Hot}
              width="65.8"
              height="24.3"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 1486.2693 323.2963)"
                className={S.Plan}
              >
                B3
              </text>
              <text
                transform="matrix(1 0 0 1 1518.1943 322.5891)"
                className={S.Number}
              >
                97
              </text>
            </g>
          </g>
        </g>
      </g>
      <g id="PHASE-1-AMENITY" className={S.Amenity}>
        <polygon
          points="1626.5,342.4 1626.5,396 1573.7,396 1573.7,355.8 1576.5,355.8 1576.5,344.3 1587.9,344.3
			1587.9,342.4 		"
        />
        <text transform="matrix(1 0 0 1 1582.8785 368.7558)">
          <tspan x="0" y="0" className="st8 st1 st9">
            INDOOR
          </tspan>
          <tspan x="-1.9" y="9.6" className="st8 st1 st9">
            AMENITY
          </tspan>
        </text>
      </g>
      <rect
        id="PHASE-1-OUTLINE"
        x="1453.5"
        y="122"
        className={S.Outline}
        width="198.8"
        height="298.3"
      />
      <g id="PHASE-1-LABEL" className={S.PhaseLabel}>
        <rect x="1453.5" y="399.6" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 1460.8807 414.683)">PHASE 1</text>
      </g>
    </g>
  );
};

export default Phase1;

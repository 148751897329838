import { useEffect } from "@wordpress/element";
import gsap from "gsap";
import S from "./Boxes.module.sass";
import PLANS, { I_Plan } from "../../../constants/floorplans";
import Box from "./Box";
import { useSelector } from "react-redux";
import {
  selectFilterBedrooms,
  selectFilterTypes,
} from "../../../store/availabilitySlice";

const Boxes = () => {
  const filterBedrooms = useSelector(selectFilterBedrooms);
  const filterTypes = useSelector(selectFilterTypes);

  const currentFilter = {
    bedrooms: filterBedrooms,
    types: filterTypes,
  };

  useEffect(() => {
    const targets = gsap.utils.toArray(".box");
    const tl = gsap.timeline();
    tl.from("#boxes", {
      autoAlpha: 0,
      duration: 0.5,
      delay: 1,
    });
    tl.from(targets, {
      duration: 0.6,
      scale: 0.25,
      autoAlpha: 0,
      stagger: {
        // wrap advanced options in an object
        each: 0.05,
        from: "random",
        grid: "auto",
        ease: "power2.inOut",
      },
    });
  }, []);

  return (
    <div id={`boxes`} className={S.Boxes}>
      {PLANS.map((plan: I_Plan, index: number) => (
        <div key={index} className={`box`}>
          <Box
            title={plan.title}
            filterBedroom={plan.filterBedrooms}
            filterTypes={plan.filterTypes}
            bath={plan.bath}
            interior={plan.interior}
            currentFilter={currentFilter}
          />
        </div>
      ))}
    </div>
  );
};

export default Boxes;

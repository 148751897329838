import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import S from "./Dropdown.module.sass";
import {
  T_Filter,
  T_Filter_Bedroom,
  T_Filter_Types,
} from "../../../../constants/floorplans";
import classNames from "classnames";
import {
  selectFilterBedrooms,
  selectFilterTypes,
  updateFilter,
} from "../../../../store/availabilitySlice";

type Props = {
  types: T_Filter;
};

const Dropdown = ({ types }: Props) => {
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);
  const currentFilterBedroom = useSelector(selectFilterBedrooms);
  const currentFilterTypes = useSelector(selectFilterTypes);
  // const [current, setCurrent] = useState<T_Filter_Bedroom | T_Filter_Types>();

  const current =
    types === "bedrooms" ? currentFilterBedroom : currentFilterTypes;

  interface I_Data {
    bedrooms: {
      list: Array<T_Filter_Bedroom>;
    };
    types: {
      list: Array<T_Filter_Types>;
    };
  }

  const data: I_Data = {
    bedrooms: {
      list: ["all bedrooms", "3 bed", "3 bed + den", "3 bed + flex"],
    },
    types: {
      list: ["all types", "duplex", "rooftop", "townhome"],
    },
  };

  // @ts-ignore
  const list = data[types];

  const toggle = () => setDropdown(!dropdown);

  const dropdownClasses = () => {
    const classes = [
      S.Menu,
      {
        [`${S.Open}`]: dropdown,
        [`${S.Close}`]: !dropdown,
      },
    ];
    return classNames(classes);
  };

  const updateCurrent = (item: T_Filter_Bedroom | T_Filter_Types) => {
    const filter =
      item === "all types" || item === "all bedrooms" ? undefined : item;
    // setCurrent(filter);
    // @ts-ignore
    dispatch(updateFilter({ filter, types }));
    toggle();
  };

  return (
    <div className={S.Dropdown}>
      <div className={S.Heading} onClick={toggle}>
        {current !== undefined ? current : `all ${types}`}
      </div>
      <ul className={dropdownClasses()}>
        {list.list.map((item: any, index: number) => (
          <li key={index} onClick={() => updateCurrent(item)}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;

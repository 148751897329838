import S from "./SVG.module.sass";
import { T_Phase } from "../../../store/availabilitySlice";
import { T_PlanTitle } from "../../../constants/floorplans";

type Props = {
  unitClasses: (unit: string, phase: T_Phase, plan: T_PlanTitle) => string;
  click: (plan: T_PlanTitle) => void;
};

const Phase5 = ({ unitClasses, click }: Props) => {
  return (
    <g id="PHASE_5">
      <g id="PHASE-5-UNITS" className={S.Units}>
        <g id="UNITS-1-6">
          <g
            onClick={() => click("A5")}
            className={unitClasses("6", "Phase 5", "A5")}
            id="UNIT-6-A5"
          >
            <polygon
              className={S.Hot}
              points="1045.5,55.9 1045.5,52.4 1058.7,52.4 1058.7,55.9 1063.9,55.9 1063.9,94.2 1031.6,94.2
					1031.6,91.2 1022.6,91.2 1022.6,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1034.0469 73.3165)"
                  className={S.Plan}
                >
                  A5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1039.9969 85.3842)"
                className={S.Number}
              >
                6
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A6")}
            className={unitClasses("5", "Phase 5", "A6")}
            id="UNIT-5-A6"
          >
            <polygon
              className={S.Hot}
              points="999.6,55.9 999.6,52.4 986.4,52.4 986.4,55.9 981.3,55.9 981.3,94.2 1013.6,94.2 1013.6,91.2
					1022.6,91.2 1022.6,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 992.5161 73.3165)"
                  className={S.Plan}
                >
                  A6
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 998.8558 85.3842)"
                className={S.Number}
              >
                5
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7")}
            className={unitClasses("4", "Phase 5", "A7")}
            id="UNIT-4-A7"
          >
            <polygon
              className={S.Hot}
              points="958.3,55.9 958.3,52.4 945.1,52.4 945.1,55.9 939.9,55.9 939.9,94.2 972.2,94.2 972.2,91.2
					981.3,91.2 981.3,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 951.5038 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 957.244 85.3842)"
                className={S.Number}
              >
                4
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7")}
            className={unitClasses("3", "Phase 5", "A7")}
            id="UNIT-3-A7"
          >
            <polygon
              className={S.Hot}
              points="917,55.9 917,52.4 903.8,52.4 903.8,55.9 898.6,55.9 898.6,94.2 930.9,94.2 930.9,91.2 939.9,91.2
					939.9,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 910.187 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 916.2162 85.3842)"
                className={S.Number}
              >
                3
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A7")}
            className={unitClasses("2", "Phase 5", "A7")}
            id="UNIT-2-A7"
          >
            <polygon
              className={S.Hot}
              points="875.7,55.9 875.7,52.4 862.5,52.4 862.5,55.9 857.3,55.9 857.3,94.2 889.6,94.2 889.6,91.2
					898.6,91.2 898.6,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 868.8701 73.3165)"
                  className={S.Plan}
                >
                  A7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 874.9696 85.3842)"
                className={S.Number}
              >
                2
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A8")}
            className={unitClasses("1", "Phase 5", "A8")}
            id="UNIT-1-A8"
          >
            <polygon
              className={S.Hot}
              points="834.4,55.9 834.4,52.4 821.2,52.4 821.2,55.9 816,55.9 816,94.2 848.3,94.2 848.3,91.2 857.3,91.2
					857.3,55.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 827.416 73.3165)"
                  className={S.Plan}
                >
                  A8
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 834.8563 85.3842)"
                className={S.Number}
              >
                1
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-56-63">
          <g
            onClick={() => click("E3")}
            className={unitClasses("63", "Phase 5", "E3")}
            id="UNIT-63-E3"
          >
            <polygon
              className={S.Hot}
              points="968.2,180.3 968.2,137.6 936.3,137.6 936.3,140.5 929.8,140.5 929.8,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 940.6613 158.1492)"
                  className={S.Plan}
                >
                  E3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 942.7344 170.2169)"
                className={S.Number}
              >
                63
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E")}
            className={unitClasses("62", "Phase 5", "E")}
            id="UNIT-62-E"
          >
            <polygon
              className={S.Hot}
              points="929.8,180.3 929.8,140.5 923.2,140.5 923.2,137.6 891.3,137.6 891.3,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 906.2277 158.1492)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 904.3545 170.2169)"
                className={S.Number}
              >
                62
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E")}
            className={unitClasses("61", "Phase 5", "E")}
            id="UNIT-61-E"
          >
            <polygon
              className={S.Hot}
              points="891.3,180.3 891.3,137.6 859.5,137.6 859.5,140.5 852.9,140.5 852.9,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 867.7778 158.1492)"
                  className={S.Plan}
                >
                  E
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 867.1097 170.2169)"
                className={S.Number}
              >
                61
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E")}
            className={unitClasses("60", "Phase 5", "E")}
            id="UNIT-60-E"
          >
            <polygon
              className={S.Hot}
              points="852.9,140.5 846.3,140.5 846.3,137.6 814.5,137.6 814.5,180.3 852.9,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 826.7543 158.1492)"
                  className={S.Plan}
                >
                  E1
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 826.8743 170.2169)"
                className={S.Number}
              >
                60
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E6")}
            className={unitClasses("59", "Phase 5", "E6")}
            id="UNIT-59-E6"
          >
            <polygon
              className={S.Hot}
              points="929.8,180.3 929.8,220.2 936.3,220.2 936.3,222.9 968.2,222.9 968.2,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 940.4142 199.1046)"
                  className={S.Plan}
                >
                  E6
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 942.7393 211.1722)"
                className={S.Number}
              >
                59
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E7")}
            className={unitClasses("58", "Phase 5", "E7")}
            id="UNIT-58-E7"
          >
            <polygon
              className={S.Hot}
              points="891.3,180.3 891.3,222.9 923.2,222.9 923.2,220.2 929.8,220.2 929.8,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 902.2697 199.1046)"
                  className={S.Plan}
                >
                  E7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 904.374 211.1722)"
                className={S.Number}
              >
                58
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E7")}
            className={unitClasses("57", "Phase 5", "E7")}
            id="UNIT-57-E7"
          >
            <polygon
              className={S.Hot}
              points="852.9,180.3 852.9,220.2 859.5,220.2 859.5,222.9 891.3,222.9 891.3,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 863.8198 199.1046)"
                  className={S.Plan}
                >
                  E7
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 866.0941 211.1722)"
                className={S.Number}
              >
                57
              </text>
            </g>
          </g>
          <g
            onClick={() => click("E5")}
            className={unitClasses("56", "Phase 5", "E5")}
            id="UNIT-56-E5"
          >
            <polygon
              className={S.Hot}
              points="814.5,180.3 814.5,222.9 846.3,222.9 846.3,220.2 852.9,220.2 852.9,180.3 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 825.2787 199.1046)"
                  className={S.Plan}
                >
                  E5
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 827.389 211.1722)"
                className={S.Number}
              >
                56
              </text>
            </g>
          </g>
        </g>
        <g id="UNITS-64-68">
          {/* cHANGED */}
          <g
            onClick={() => click("A4")}
            className={unitClasses("68", "Phase 5", "A4")}
            id="UNIT-68-A4"
          >
            <polygon
              className={S.Hot}
              points="1049.2,344.1 1010.6,344.1 1010.6,311.4 1014.2,311.4 1014.2,302.8 1049.2,302.8 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1022.6264 321.76)"
                  className={S.Plan}
                >
                  A4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1025.6409 333.8277)"
                className={S.Number}
              >
                68
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A")}
            className={unitClasses("67", "Phase 5", "A")}
            id="UNIT-67-A"
          >
            <polygon
              className={S.Hot}
              points="1049.2,302.8 1010.6,302.8 1010.6,270.1 1014.2,270.1 1014.2,261.5 1049.2,261.5 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1026.8901 280.4531)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1025.8108 292.5208)"
                className={S.Number}
              >
                67
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A")}
            className={unitClasses("66", "Phase 5", "A")}
            id="UNIT-66-A"
          >
            <polygon
              className={S.Hot}
              points="1049.2,261.5 1010.6,261.5 1010.6,228.8 1014.2,228.8 1014.2,220.2 1049.2,220.2 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1026.8901 239.146)"
                  className={S.Plan}
                >
                  A
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1025.5559 251.2137)"
                className={S.Number}
              >
                66
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A3")}
            className={unitClasses("65", "Phase 5", "A3")}
            id="UNIT-65-A3"
          >
            <polygon
              className={S.Hot}
              points="1049.2,220.2 1010.6,220.2 1010.6,187.5 1014.2,187.5 1014.2,178.9 1049.2,178.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1022.8725 197.8392)"
                  className={S.Plan}
                >
                  A3
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1025.7307 209.9069)"
                className={S.Number}
              >
                65
              </text>
            </g>
          </g>
          <g
            onClick={() => click("A4")}
            className={unitClasses("64", "Phase 5", "A4")}
            id="UNIT-64-A4"
          >
            <polygon
              className={S.Hot}
              points="1049.2,137.6 1010.6,137.6 1010.6,170.3 1014.2,170.3 1014.2,178.9 1049.2,178.9 				"
            />
            <g>
              <g>
                <text
                  transform="matrix(1 0 0 1 1022.4565 156.5321)"
                  className={S.Plan}
                >
                  A4
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 1025.4358 168.5998)"
                className={S.Number}
              >
                64
              </text>
            </g>
          </g>
        </g>
      </g>
      <polygon
        id="PHASE-5-OUTLINE"
        className={S.Outline}
        points="1069,420.3 989.5,420.3 989.5,242.7 795.7,242.7 795.7,0 1069,0 	"
      />
      <g id="PHASE-5-LABEL" className={S.PhaseLabel}>
        <rect x="795.7" width="73.8" height="20.7" />
        <text transform="matrix(1 0 0 1 803.1169 15.0637)">PHASE 5</text>
      </g>
    </g>
  );
};

export default Phase5;

import S from "./SVG.module.sass";

const Legend = () => {
  return (
    <g id="LEGEND" className={S.Legend}>
      <rect x="1826.4" y="502.1" className={S.Box} width="77.9" height="52.4" />
      <rect x="1838" y="511.9" className={S.Sold} width="13.4" height="13.4" />
      <text transform="matrix(1 0 0 1 1858.8594 523.4209)">SOLD</text>
      <rect x="1838" y="532.9" className={S.Hold} width="13.4" height="13.4" />
      <text transform="matrix(1 0 0 1 1858.1582 544.3809)">HOLD</text>
    </g>
  );
};

export default Legend;

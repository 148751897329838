export type T_Filter = "bedrooms" | "types" | string;

export type T_Filter_Bedroom =
  | "all bedrooms"
  | "3 bed"
  | "3 bed + den"
  | "3 bed + flex"
  | undefined;

export type T_Filter_Types =
  | "all types"
  | "duplex"
  | "rooftop"
  | "townhome"
  | undefined;

export type T_PlanTitle =
  | "A"
  | "A1"
  | "A2"
  | "A3"
  | "A4"
  | "A5"
  | "A6"
  | "A7"
  | "A8"
  | "B"
  | "B1"
  | "B3"
  | "B4"
  | "B5"
  | "C"
  | "C1"
  | "C2"
  | "C3"
  | "C4"
  | "C5"
  | "D"
  | "D1"
  | "D2"
  | "D3"
  | "D5"
  | "E"
  | "E3A"
  | "E1"
  | "E3"
  | "E4"
  | "E5"
  | "E6"
  | "E7"
  | "F"
  | "G";

export type T_Bed = 1 | 2 | 3 | 4;

export type T_Bath = 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5;

export type T_Additional = "den" | "flex";

export interface I_Plan {
  title: T_PlanTitle;
  bed: T_Bed;
  bath: T_Bath;
  additional?: T_Additional;
  interior: number;
  deck?: number;
  deckInfo?: string;
  rooftop?: number;
  total?: number;
  garage?: number;
  filterBedrooms: T_Filter_Bedroom;
  filterTypes: T_Filter_Types;
}

const Floorplans: Array<I_Plan> = [
  {
    title: "A",
    bed: 3,
    bath: 2.5,
    interior: 1477,
    deck: 89.5,
    total: 1566.5,
    garage: 521,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A1",
    bed: 3,
    bath: 2.5,
    interior: 1485,
    deck: 89.5,
    total: 1574.5,
    garage: 525,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A2",
    bed: 3,
    bath: 2.5,
    interior: 1487,
    deck: 88,
    total: 1575,
    garage: 476,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A3",
    bed: 3,
    bath: 2.5,
    interior: 1477,
    deck: 88,
    total: 1565,
    garage: 525,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A4",
    bed: 3,
    bath: 2.5,
    interior: 1485,
    deck: 88,
    total: 1573,
    garage: 525,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A5",
    bed: 3,
    bath: 2.5,
    interior: 1485,
    garage: 525,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A6",
    bed: 3,
    bath: 2.5,
    interior: 1477,
    garage: 521,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A7",
    bed: 3,
    bath: 2.5,
    interior: 1477,
    garage: 521,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "A8",
    bed: 3,
    bath: 2.5,
    interior: 1485,
    garage: 525,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "B",
    bed: 3,
    bath: 2.5,
    interior: 1426,
    deck: 55.8,
    total: 1481.8,
    garage: 502,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "B1",
    bed: 3,
    bath: 2.5,
    interior: 1424,
    deck: 55.8,
    total: 1479.8,
    garage: 502,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "B3",
    bed: 3,
    bath: 2.5,
    interior: 1512,
    deck: 66.6,
    total: 1579.6,
    garage: 509,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "B4",
    bed: 3,
    bath: 2.5,
    interior: 1510,
    deck: 66.6,
    total: 1576.6,
    garage: 509,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "B5",
    bed: 3,
    bath: 2.5,
    interior: 1512,
    deck: 66.6,
    total: 1578.6,
    garage: 509,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "D",
    bed: 3,
    bath: 2.5,
    additional: "flex",
    interior: 1624,
    deck: 64,
    total: 1688,
    garage: 412,
    filterBedrooms: "3 bed + flex",
    filterTypes: "townhome",
  },
  {
    title: "D1",
    bed: 3,
    bath: 2.5,
    additional: "flex",
    interior: 1669,
    deck: 60.4,
    total: 1729.4,
    garage: 411,
    filterBedrooms: "3 bed + flex",
    filterTypes: "duplex",
  },
  {
    title: "D2",
    bed: 3,
    bath: 2.5,
    additional: "flex",
    interior: 1686,
    deck: 85,
    total: 1771,
    garage: 415,
    filterBedrooms: "3 bed + flex",
    filterTypes: "townhome",
  },
  {
    title: "D3",
    bed: 3,
    bath: 2.5,
    interior: 1633,
    deck: 85,
    total: 1718,
    garage: 415,
    filterBedrooms: "3 bed",
    filterTypes: "townhome",
  },
  {
    title: "D5",
    bed: 3,
    bath: 2.5,
    additional: "den",
    interior: 1709,
    deck: 65,
    total: 1774,
    garage: 415,
    filterBedrooms: "3 bed + den",
    filterTypes: "duplex",
  },
  {
    title: "E",
    bed: 3,
    bath: 3.5,
    additional: "flex",
    interior: 1964,
    rooftop: 145,
    total: 2109,
    garage: 423,
    filterBedrooms: "3 bed + flex",
    filterTypes: "rooftop",
  },
  {
    title: "E1",
    bed: 3,
    bath: 3.5,
    additional: "flex",
    interior: 1983,
    rooftop: 141,
    total: 2124,
    garage: 423,
    filterBedrooms: "3 bed + flex",
    filterTypes: "rooftop",
  },
  {
    title: "E3",
    bed: 3,
    bath: 3.5,
    additional: "flex",
    interior: 1949,
    rooftop: 145,
    total: 2094,
    garage: 427,
    filterBedrooms: "3 bed + flex",
    filterTypes: "rooftop",
  },
  {
    title: "E5",
    bed: 3,
    bath: 2.5,
    interior: 1467,
    rooftop: 139,
    total: 1606,
    garage: 463,
    filterBedrooms: "3 bed",
    filterTypes: "rooftop",
  },
  {
    title: "E6",
    bed: 3,
    bath: 2.5,
    interior: 1422,
    rooftop: 139,
    total: 1561,
    garage: 459,
    filterBedrooms: "3 bed",
    filterTypes: "rooftop",
  },
  {
    title: "E7",
    bed: 3,
    bath: 3.5,
    interior: 1931,
    rooftop: 139,
    total: 2070,
    garage: 427,
    filterBedrooms: "3 bed",
    filterTypes: "rooftop",
  },
  {
    title: "F",
    bed: 3,
    bath: 3.5,
    additional: "flex",
    interior: 1871,
    deck: 52,
    total: 1923,
    garage: 408,
    filterBedrooms: "3 bed + flex",
    filterTypes: "townhome",
  },
  {
    title: "G",
    bed: 3,
    bath: 2.5,
    additional: "flex",
    interior: 1531,
    deck: 52,
    deckInfo: "(X2)",
    total: 1635,
    garage: 413,
    filterBedrooms: "3 bed + flex",
    filterTypes: "townhome",
  },
];

export default Floorplans;

import S from "./Sitemap.module.sass";
import SVG from "./SVG";
import SiteMap from "../../images/siteplan.jpg";
import { useEffect } from "@wordpress/element";
import gsap, { Power4 } from "gsap";

const Sitemap = () => {
  useEffect(() => {
    gsap.fromTo(
      "#sitemap",
      {
        opacity: 0,
        scale: 0.98,
        clipPath: "polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)",
      },
      {
        opacity: 1,
        scale: 1,
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        duration: 2,
        delay: 0.25,
        ease: Power4.easeInOut,
      },
    );
  }, []);
  return (
    <div id={`sitemap`} className={S.Sitemap}>
      <div className={S.Background}>
        <img src={SiteMap} alt={`rendering of site map`} />
      </div>
      <div className={S.Overlay} />
      <SVG />
    </div>
  );
};

export default Sitemap;

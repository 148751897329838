import { useEffect } from "@wordpress/element";
import gsap, { Power3 } from "gsap";
import S from "./Filter.module.sass";
import Dropdown from "./Dropdown";

const Filter = () => {
  useEffect(() => {
    gsap.from("#filter-heading", {
      duration: 1,
      y: 100,
      autoAlpha: 0,
      delay: 1,
      ease: Power3.easeOut,
    });
    gsap.from("#dropdownmenus", {
      duration: 1,
      xPercent: 100,
      delay: 1,
      autoAlpha: 0,
      ease: Power3.easeOut,
    });
  }, []);
  return (
    <div className={S.Filter}>
      <h1 id={`filter-heading`}>floor plan types</h1>
      <div id={`dropdownmenus`} className={S.DropdownMenus}>
        <Dropdown types={`bedrooms`} />
        <Dropdown types={`types`} />
      </div>
    </div>
  );
};

export default Filter;

import { createRoot } from "@wordpress/element";

import { store } from "./store";
import App from "./app/App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./sass/app.global.sass";

const domNode = document.getElementById(
  "elwynn-green-interactive-sitemap-root",
);
// @ts-ignore
const root = createRoot(domNode);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
// const applicationContainer = document.getElementById(
//   "elwynn-green-interactive-sitemap-root",
// );
//
// applicationContainer &&
//   render(
//     <Provider store={store}>
//       <App />
//     </Provider>,
//     applicationContainer,
//   );

serviceWorker.unregister();

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import {
  T_Filter,
  T_Filter_Bedroom,
  T_Filter_Types,
} from "../constants/floorplans";
import axios from "axios";

const webiteURL =
  "https://presentation.elwynngreen.com/wp-json/elwynn-green/v2/unit";

const _ = require("lodash");

export type T_Phase =
  | "Phase 1"
  | "Phase 2"
  | "Phase 3"
  | "Phase 4"
  | "Phase 5"
  | "Phase 6"
  | "Phase 7"
  | "Phase 8"
  | "Phase 9";

export interface IUnit {
  id: number;
  unit: string;
  status: string;
  phase: T_Phase;
}

interface IinitialState {
  units: Array<IUnit>;
  filter: {
    bedrooms: T_Filter_Bedroom | T_Filter_Types;
    types: T_Filter_Bedroom | T_Filter_Types;
  };
}

const initialState: IinitialState = {
  units: [],
  filter: {
    bedrooms: undefined,
    types: undefined,
  },
};

// export const fetchUnits = createAsyncThunk(
//   "availability/fetchUnits",
//   async () => {
//     const rr = _.random(1, 100000);
//     const newURL = `${webiteURL}?rr=${rr}`;
//     const response = await axios.get(newURL);
//     return response.data;
//   },
// );

export const fetchUnits = createAsyncThunk(
  "availability/fetchUnits",
  // @ts-ignore
  async () => {
    const rr = _.random(1, 100000);
    const newURL = `${webiteURL}?rr=${rr}`;
    const response = await axios.get(newURL);
    return response.data;
  },
);

export const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    updateFilter: {
      reducer: (
        state,
        action: PayloadAction<{
          filter: T_Filter_Bedroom | T_Filter_Types;
          types: T_Filter;
        }>,
      ) => {
        const { filter, types } = action.payload;
        if (types === "bedrooms") {
          state.filter.bedrooms = filter;
          state.filter.types = undefined;
        }

        if (types === "types") {
          state.filter.bedrooms = undefined;
          state.filter.types = filter;
        }
      },
      prepare: ({
        filter,
        types,
      }: {
        filter: T_Filter_Bedroom | T_Filter_Types;
        types: T_Filter;
      }) => ({
        payload: { filter, types },
      }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnits.fulfilled, (state, action) => {
      state.units = action.payload;
    });
  },
});

export const { updateFilter } = availabilitySlice.actions;

export const selectUnit = (state: RootState) => state.availability.units;

export const selectFilterBedrooms = (state: RootState) =>
  state.availability.filter.bedrooms;

export const selectFilterTypes = (state: RootState) =>
  state.availability.filter.types;

export default availabilitySlice.reducer;

import S from "./Plans.module.sass";
import Filter from "./Filter";
import Boxes from "./Boxes";

const Plans = () => {
  return (
    <div className={S.Plans}>
      <div className={S.Container__Filter}>
        <Filter />
      </div>
      <div className={S.Container__Boxes}>
        <Boxes />
      </div>
    </div>
  );
};

export default Plans;
